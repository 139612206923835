import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IncomingCall {
  waitingList: WaitingListItem[];
}
interface StateTypes {
  sidebarToggle: boolean;
  particularPrescription: viewPrescriptionTypes | null;
  viewDocument: boolean;
  incomingCalls: IncomingCall[];
  videoCallActiveTab: string;
}
const initialState: StateTypes = {
  sidebarToggle: false,
  particularPrescription: null,
  viewDocument: false,
  incomingCalls: [],
  videoCallActiveTab: "",
};
const CommonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    handleToggle: (state) => {
      state.sidebarToggle = !state.sidebarToggle;
    },
    handleSetIncomingCalls: (state, action: PayloadAction<IncomingCall[]>) => {
      state.incomingCalls = action.payload;
    },

    closeSidebar: (state, action) => {
      state.sidebarToggle = action?.payload ?? false;
    },
    handleCloseDocument: (state) => {
      state.viewDocument = false;
    },
    handleVideoCallActiveTab: (
      state,
      action: PayloadAction<StateTypes["videoCallActiveTab"]>
    ) => {
      state.videoCallActiveTab = action.payload ?? "";
    },
    handleViewDocument: (
      state,
      action: PayloadAction<StateTypes["particularPrescription"]>
    ) => {
      state.particularPrescription = action.payload ?? null;
      state.viewDocument = true;
    },
  },
});

export const {
  handleToggle,
  closeSidebar,
  handleViewDocument,
  handleCloseDocument,
  handleSetIncomingCalls,
  handleVideoCallActiveTab
} = CommonSlice.actions;
export default CommonSlice;
