import React, { FC } from 'react'
import styles from "../../assets/styles/videocall.module.css"
interface PropsType {
  healthDetails: AppointmentDetailsResponse['healthDetails'] | undefined
}
const VitalMonitoring: FC<PropsType> = ({ healthDetails }) => {
  return (
    <div className={styles.VitalMonitoringMain}>
      <div className={styles.secHeading}>
        <h3>Vital Monitoring</h3>
      </div>
      <div className='flex flex-col gap-6'>
        <div className={styles.vitalMCard}>
          <div className={styles.boxHead}>
            <h4>Blood Pressure</h4>
          </div>
          <div className={styles.boxContMain}>
            <div className={styles.boxCont}>
              <h4>{healthDetails?.bloodPressure?.split('/') &&healthDetails?.bloodPressure?.split('/')[0]} <sub>mmHg</sub></h4>
              <p>SYS</p>
            </div>
            <div className={styles.boxCont}>
              <h4>{healthDetails?.bloodPressure?.split('/') &&healthDetails?.bloodPressure?.split('/')[1]} <sub>mmHg</sub></h4>
              <p>DIA</p>
            </div>
            <div className={styles.boxCont}>
              <h4>{healthDetails?.heartRate} <sub>BPM</sub></h4>
              <p>Pulse Rate</p>
            </div>
          </div>
        </div>
        <div className={styles.vitalMCard}>
          <div className={styles.boxHead}>
            <h4>Temperature</h4>
          </div>
          <div className={`${styles.boxContMain} justify-center`}>
            <div className={styles.boxCont}>
              <h4>{healthDetails?.temperature} <sub>°F</sub></h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VitalMonitoring