import React, { useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import IncomingCallList from './IncomingCallList'
import styles from "../../assets/styles/dashboard.module.css"
import { getDashboardApi } from '../../utils/api/services'
import Loader from '../../components/common/Loader'
import { MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import NoDataFound from '../../components/common/NoDataFound'
import { ShowPasswordIcon } from '../../utils/functions/Icons'
import { useSelector } from 'react-redux'
import { RootState } from '../../utils/store'
import Cookies from 'js-cookie'
import PatientChart from '../../components/dashboard/PatientChart'
import { monthsData, yearsData } from '../../utils/functions/Data'
import AppointmentChart from '../../components/dashboard/AppointmentChart'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState<DoctorDashboardData | null>(null);
    const [loading, setLoading] = useState(false)
    const [selectedYear, setSelectedYear] = useState<number | null>(null);
    const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
    const { LoginData } = useSelector((state: RootState) => state.auth)

    useEffect(() => {
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        setSelectedMonth(currentMonth);
        setSelectedYear(currentYear);
    }, []);

    const fetchDashboardData = async (year: number, month: number) => {
        try {
            setLoading(true)
            const result = await getDashboardApi(year, month);
            if (result?.success) {
                setLoading(false)
                setDashboardData(result?.data)
            } else { setLoading(false) }
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (selectedMonth && selectedYear) {
            fetchDashboardData(selectedYear, selectedMonth);
        }
    }, [selectedMonth, selectedYear]);

    const handleCreatePres = () => {
        const token = Cookies.get('token');
        if (token && LoginData?.email && LoginData?.id)
            window.open(`https://prescription.akosmd.in?email=${LoginData?.email}&token=${token}&cid=${LoginData?.id}`)
    }

    if (loading) {
        return <Loader />
    }
    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2>Dashboard</h2>
                </div>
                <div className={styles.main}>
                    <div className='grid grid-cols-1 md:grid-cols-5 gap-6'>
                        <section className={`${styles.topSection} col-span-3`}>
                            <div className="grid grid-cols-2 gap-6">
                                <div className={styles.ptBox}>
                                    <h4>My Patients</h4>
                                    <p>{dashboardData?.mypatientCount ? dashboardData?.mypatientCount : 0}</p>
                                </div>
                                <div className={styles.ptBox}>
                                    <h4>Chat Pending</h4>
                                    <p>{dashboardData?.pendingChats && dashboardData?.pendingChats?.length>0 ? dashboardData?.pendingChats[0]?.totalChats : 0}</p>
                                </div>
                            </div>
                        </section>
                        <div className='col-span-2'>
                            <IncomingCallList />
                        </div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mt-6'>
                        <div className={`${styles.chartSec1}`}>
                            <div className={styles.filter}>
                                <h3>Patients Visits Summary by year</h3>
                                <Select
                                    className="custumTextField profileInput forYearFilter"
                                    name="year"
                                    value={selectedYear || ''}
                                    onChange={(e) => setSelectedYear(Number(e.target.value))}
                                >
                                    {yearsData.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <PatientChart patientStats={dashboardData?.patientStats} />
                        </div>
                        <div className={`${styles.chartSec2}`}>
                            <div className={styles.filter}>
                                <h3>Appointments made this month</h3>
                                <Select
                                    className="custumTextField profileInput forYearFilter"
                                    name="month"
                                    displayEmpty
                                    value={selectedMonth || ''}
                                    onChange={(e) => setSelectedMonth(Number(e.target.value))}
                                >
                                    {monthsData.map((month) => (
                                        <MenuItem key={month?.id} value={month?.id}>
                                            {month?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <AppointmentChart appointmentStats={dashboardData?.appointmentStats} />
                        </div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mt-8'>
                        <div>
                            <div className={`${styles.appointmentsSec}`}>
                                <div className={`${styles.presSecHead}`}>
                                    <h3>Appointments</h3>
                                </div>
                                <div className={`${styles.appointmentsHeader} grid grid-cols-3 gap-6 mt-3`}>
                                    <div></div>
                                    <div className={styles.headerItem}>Today</div>
                                    <div className={styles.headerItem}>Overall</div>
                                </div>
                                <div className={`${styles.showApData} grid grid-cols-3 gap-6 mt-3`}>
                                    <div className={styles.label}>Upcoming</div>
                                    <div className={styles.value}>{dashboardData?.appointments?.upcoming}</div>
                                    <div className={styles.value}>{dashboardData?.appointments?.totalUpcoming}</div>
                                </div>
                                <div className={`${styles.showApData} grid grid-cols-3 gap-6 mt-3`}>
                                    <div className={styles.label}>Completed</div>
                                    <div className={styles.value}>{dashboardData?.appointments?.completed}</div>
                                    <div className={styles.value}>{dashboardData?.appointments?.totalCompleted}</div>
                                </div>
                                <div className={`${styles.showApData} grid grid-cols-3 gap-6 mt-3`}>
                                    <div className={styles.label}>Missed</div>
                                    <div className={styles.value}>{dashboardData?.appointments?.missed}</div>
                                    <div className={styles.value}>{dashboardData?.appointments?.totalMissed}</div>
                                </div>
                            </div>
                        </div>
                        <section className={`${styles.presSec} col-span-2`}>
                            <div className={`${styles.presSecHead}`}>
                                <h3>Prescription Management</h3>
                                <button>View all</button>
                            </div>
                            <div className='flex justify-end mb-6'>
                                <button className='fillButton' onClick={handleCreatePres}>+ Create New Prescription</button>
                            </div>
                            <TableContainer component={Paper} className='TableContainer'>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead className='custumTableHead'>
                                        <TableRow className={styles.completeTableRow}>
                                            <TableCell className='whitespace-nowrap'>Patient Name</TableCell>
                                            <TableCell align="right" className='whitespace-nowrap'>Date</TableCell>
                                            <TableCell align="right" className='whitespace-nowrap'>Time</TableCell>
                                            <TableCell align="right" className='whitespace-nowrap'>Phone</TableCell>
                                            <TableCell align="right" className='whitespace-nowrap'></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {
                                        dashboardData?.prescriptions && dashboardData?.prescriptions?.length > 0 ?
                                            <TableBody>
                                                {
                                                    dashboardData?.prescriptions?.map((item, index) => {
                                                        return (
                                                            <TableRow
                                                                key={index}
                                                                className='custumTableRow'
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    <div className={styles.doctorSec}>
                                                                        {item?.patientName}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item?.appointmentDate}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item?.appointmentTime}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    <button>{item?.phone}</button>
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    <button disabled={!item?.prescriptionURL} onClick={() => window.open(item?.prescriptionURL)}>
                                                                        <ShowPasswordIcon color={`${!item?.prescriptionURL ? '' : '#0562CC'}`} />
                                                                    </button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }

                                            </TableBody>
                                            : <TableBody>
                                                <TableRow
                                                    className='custumTableRow'
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" colSpan={5}>
                                                        <NoDataFound />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                    }
                                </Table>
                            </TableContainer>
                        </section>
                    </div>
                </div>
            </div>
        </CustomContainer>
    )
}

export default Dashboard