import React, { useEffect, useState } from 'react'
import { addUserToWaitingApi, checkStatusWaitingRoomApi, getGenerateCallIdByPatientApi, getOpenTokRoomKeys, getSavedUserIdApi, getTalkToDoctorApi, getTokenApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getDeviceInfo } from '../../utils/functions/common';
import WaitingStatusCom from '../../components/chat/WaitingStatusCom';
import Loader from '../../components/common/Loader';
import { useLocation } from 'react-router-dom';
import VideoCall from '../../components/chat/VideoCall';
import UseSocket from '../../utils/functions/useSocket';
import { useDispatch } from 'react-redux';
import { closeSidebar, handleToggle } from '../../utils/store/common/CommonSlice';

const CallNow = () => {
  const apiKey = '46364672';
  const [sessionId, setSessionId] = useState('');
  const [token, setToken] = useState('')
  const [TalkToDoctorData, setTalkToDoctorData] = useState<WaitingListItem | null>(null);
  const [loading, setLoading] = useState(false)
  const [callid, setCallid] = useState<number | null>(null)
  const location = useLocation();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(closeSidebar(true))
  }, [location.pathname])

  const fetchOpenTokRoomKeys = async (id: number) => {
    try {
      const result = await getOpenTokRoomKeys(id);
      if (result?.success) {
        const KeyData = result?.data;
        if (KeyData) {
          setSessionId(KeyData?.session);
          setToken(KeyData?.token);
        }
      }
    } catch (error: any) {
      console.error(error.message);
    }
  }

  useEffect(() => {
    if (location?.state?.waitingList?.call_id) {
      setSessionId(location.state?.opentokKeys?.session)
      setToken(location.state?.opentokKeys?.token)
      setCallid(location.state?.waitingList?.call_id)
      setTalkToDoctorData(location.state?.waitingList)
      fetchOpenTokRoomKeys(location.state?.waitingList?.patient_id)
    }
  }, [])


  // const apiKey = '46364672';
  // const sessionId = '2_MX40NjM2NDY3Mn5-MTcyMjMyMzYxMTU2N35McHVmVVNZbnVRRUp5ZGhNVHlVVGR2UTF-fn4';
  // const token = 'T1==cGFydG5lcl9pZD00NjM2NDY3MiZzaWc9MTg0OTY0MjI0NDlhYjA5ZjExOGYzM2VhMTcxNTVjOWM3ZGMxYTdiMDpzZXNzaW9uX2lkPTJfTVg0ME5qTTJORFkzTW41LU1UY3lNak15TXpZeE1UVTJOMzVNY0hWbVZWTlpiblZSUlVwNVpHaE5WSGxWVkdSMlVURi1mbjQmY3JlYXRlX3RpbWU9MTcyMjMyMzYxMiZub25jZT0wLjM1MDcxMjU0NDM0NDYyNTMmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTcyMjQxMDAxMiZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==';

  if (loading) {
    return <Loader />
  }
  return (
    <>
      {
        token &&
        <VideoCall apiKey={apiKey} sessionId={sessionId} token={token} TalkToDoctorData={TalkToDoctorData} callid={callid} />
      }
    </>
  )
}

export default CallNow